import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './css/primary.css';
import './css/secondary.css';
import './css/header.css';
import './css/footer.css';
import './css/button.css';
import {
  createBrowserRouter, RouterProvider, Outlet, useNavigate
} from 'react-router-dom';
import HomeContentComp from './homeContent';
import PageContentComp from './pageContent';
//=======================================
const myRouter = createBrowserRouter([{
  path: '/',
  element: <PrimeComp />,
  errorElement: <MyErrorComponent />,
  children: [{
    path: '/:pageId',
    element: <PageContentComp />
  },{
    index: true,
    element: <HomeContentComp />
  }]
}]);
//=======================================
function MyErrorComponent() {
  const myRedirect = useNavigate();
  useEffect(
    () => { myRedirect('/'); },
    [myRedirect]
  );
  return <>Redirecting</>;
}
//=======================================
function PrimeComp() {
  return <Outlet />;
}
//=======================================
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={myRouter} />
  </React.StrictMode>
);
