export default function ValuationComp() {
    return <div>
      <div className="spacer-12"></div>
      <b>VALUATION (price):</b>
      <div className="spacer-12"></div>
      One hour of "<b>advanced</b>" guitar instructions:<br />
      $50 (USC): includes tax & currency transfer fee.<br />
      The price & content (stated in this website)<br />
      are subject to change without notice.<br />
      <div className="spacer-12"></div>
      Lesson schedule can be weekly, every two weeks or negotiated.<br />
      A reasonable number of free quick questions between lessons.
      <div className="spacer-24"></div>
      STUDENTS NEW TO GUITAR:
      <div className="spacer-12"></div>
      The price is the same. But I recommend ...<br />
      Hobbyist: Find a less expensive instructor.<br />
      Sincerely, my approach isn't "geared" for hobbyists.<br />
      Learning & applying <b>Jazz theory</b> is intensely challenging.
      <div className="spacer-12"></div>
      Desire to become an <b>advanced</b> player:<br />
      You require a proper instructor.<br />
      "Two+" week lesson cycle is recommended<br />
      in order to complete & absorb "homework" tasks.<br />
      This way, your $50 is spread out & <b>optimized</b>.<br />
      Unless you are highly motivated & have abundant practice time,<br />
      one lesson every week is too demanding (in a number of ways).<br />
      This <b>may</b> be different for <b>intermediate level</b> guitarists.
      <div className="spacer-12"></div>
      Side-note: My "additive" approach<br />
      improves your guitar playing & musicianship,<br />
      regardless to when you choose to discontinue.<br />
      If you follow through with your "homework".<br />
      <div className="spacer-24"></div>
      I wish you "prosperous & enjoyable" studies.<br />
      Thank-you, Michael Rooney
      <div className="spacer-12"></div>
    </div>;
  }