export default function ExperienceComp() {
    return <div>
      <div className="spacer-12"></div>
      <b>MY WORK EXPERIENCE</b>:
      <div className="spacer-12"></div>
      Full-time musician: 1995 to 2014<br />
      Countries: USA, Canada, South Korea<br />
      <div className="spacer-12"></div>
      I play lead electric guitar & lead/harmony vocals.<br />
      I performed with four bands over an 18 year period.
      <div className="spacer-12"></div>
      I perpetually continue to study & practice <b>Jazz Theory</b>.<br />
      I play classic rock, country, popular music, ...
      <div className="spacer-12"></div>
      A few of my favorite guitar players are:<br />
      Allan Holdsworth, Brent Mason, Eric Johnson, many others ...
      <div className="spacer-12"></div>
      I have a number of computer skills.<br />
      Including: Audio & video production,<br />
      JavaScript programming related skills (including ReactJS)<br />
      For three years (regarding a popular Udemy ReactJS course)<br />
      I've been a volunteer teaching assistant.
      <div className="spacer-12"></div>
      My residence: North/West Wisconsin, USA
      <div className="spacer-12"></div>
    </div>;
  }