import {useNavigate} from 'react-router-dom';
import FeaturesComp from './pageComps/features';
import EducationComp from './pageComps/education';
import ExperienceComp from './pageComps/experience';
import ValuationComp from './pageComps/valuation';
import FooterComp from './footerContent';
//===================================
const compsObjectMap = {
  'features': <FeaturesComp />,
  'education': <EducationComp />,
  'experience': <ExperienceComp />,
  'valuation': <ValuationComp />
};
//-----------------------------------
export default function PageContentComp() {
  const currentPageId = localStorage.getItem('id');
  const pageNavigate = useNavigate();
  function homeClickHandler() {
    localStorage.setItem('id', '');
    pageNavigate('/');
  }
  //- - - - - - - - - - - - - - - - -
  return <div id="page-comp" className="template-css">
    <header
      onClick={homeClickHandler}
      className="header-fixed"
    >
      <div>G.L.A.T.A.</div>
      <div>Click here</div>
      <div className="home-text">
        Go to Home
      </div>
    </header>
    <header className="header-elem"></header>
    <main className="main-elem">
      {compsObjectMap[currentPageId]}
      <div className="spacer-12"></div>
    </main>
    <FooterComp />
  </div>;
}