import {useState} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
//=======================================
export default function EmailComp(props) {
  const [
    reCaptchaStatus,
    setReCaptchaStatus
  ] = useState(false);
  //- - - - - - - - - - - - - - - -
  function reCaptchaHandler(myReCapValue) {
    setReCaptchaStatus(
      myPrevBoolean => !myPrevBoolean
    );
  }
  const emailJsx = <b>
    <a href="mailto:glata@earthlink.net" className="email-css">
        glata@earthlink.net
    </a>
  </b>;
  const notRobotJsx = <>
    (first) <span className="warning-css">
      Verify you are not a robot
    </span>
  </>;
  //- - - - - - - - - - - - - - - -
  return <div>
    <div className="recaptcha-css">
      <ReCAPTCHA
        sitekey='6Lf5sH4dAAAAAEuJSnu26j9on0HVjpZhjCRzSu1d'
        theme='dark'
        onChange={reCaptchaHandler}
      />
    </div>
    Email me at: {reCaptchaStatus ? emailJsx : notRobotJsx}
  </div>;
}