import {useNavigate} from 'react-router-dom';
import {v4 as uuidv4} from 'uuid';// random version
import ButtonComp from './buttonContent';
import EmailComp from './emailContent';
import FooterComp from './footerContent';
//====================================
const pagesArray = [
  {uuId: uuidv4(), id: 'features', title: 'Lesson Features (continued)'},
  {uuId: uuidv4(), id: 'education', title: 'My Formal Education'},
  {uuId: uuidv4(), id: 'experience', title: 'My Work Experience'},
  {uuId: uuidv4(), id: 'valuation', title: 'Valuation (price)'}
];
//====================================
function pageButtonMap(clickHandler) {
  return pagesArray.map(
    currentPage => <ButtonComp
      key={currentPage.uuId}
      idProps={currentPage.id}
      titleProps={currentPage.title}
      clickHandlerProps={clickHandler}
      cssClassProps={'page-button'}
    />
  );
}
//====================================
export default function HomeContentComp() {
  const pageNavigate = useNavigate();
  function clickHandler(idParameter) {
    localStorage.removeItem('id');
    localStorage.setItem('id', idParameter);
    pageNavigate(`/${idParameter}`);
  }
  //- - - - - - - - - - - - - - - - -
  return <div id="home-comp" className="template-css">
    <header className="header-fixed">
      <div className="glata-text">
        <span className="gtr-lessons-text">Guitar Lessons</span>
        &#160;Advanced Theory Applied
      </div>
      <div className="header-glada">G.L.A.T.A.</div>
    </header>
    <header className="header-elem"></header>
    <main className="main-elem">
      <div className="spacer-12"></div>
      {pageButtonMap(clickHandler)}
      <div>
        <div className="spacer-12"></div>
        Hello, I'm <b>Michael Rooney</b>.<br />
        I play electric lead guitar.<br />
        I'm a 1994 <b>Berklee College of Music</b> graduate.<br />
        I studied an intensive (four year) Jazz Performance program.
        <div className="spacer-12"></div>
        <b>GUITAR LESSONS</b>:<br />
        I teach one to one instruction via Skype.<br />
        You can see me play when we Skype.<br />
        There is no cost if you decline during the initial 30 minutes.<br />
        Pay after each lesson via Venmo (USA).<br />
        <div className="spacer-12"></div>
        <EmailComp />
        <div className="spacer-24"></div>
        <b>LESSON FEATURES</b>:<br />
        In the moment questions & answers.<br />
        You can't get this from prerecorded video lessons.
        <div className="spacer-12"></div>
        Customized orderly learning procedures.
        <div className="spacer-12"></div>
        If you're preparing to attend a<br />
        guitar institution (music college), I can help.
      </div>
      <div>
        <div className="spacer-12"></div>
        <img
          src="imgs/mlr-guitar.jpg"
          alt="michael with guitar"
          className="cover-image"
        />
      </div>
      <div className="spacer-12"></div>
    </main>
    <FooterComp />
  </div>;
}