export default function FeaturesComp() {
    return <div>
      <div className="spacer-12"></div>
      <b>LESSON FEATURES</b> (continued):
      <div className="spacer-12"></div>
      Learn approaches that accumulate and empower each other.<br />
      Instead of approaches that lead to a "dead-end" or a wall.
      <div className="spacer-12"></div>
      <b>JAZZ THEORY</b> is an important tool to develop your personal style.<br />
      Jazz theory translates to many genres & different instruments.<br />
      Break out of "the box" and play over the whole guitar neck.<br />
      You don't necessarily become a Jazz player.<br />
      Jazz theory can help make you a better player/musician.
      <div className="spacer-12"></div>
      <b>Reading</b> is not prioritized but can optionally be taught.<br />
      <b>Ear-training</b> in relation to guitar (develop relative-pitch).<br />
      How to see your guitar-neck inside your head, as you play.
      <div className="spacer-12"></div>
      <b>Chords</b> & <b>Scales</b> (soloing) are closely synonymous.<br />
      There is a way to understand chords, scales,<br />
      soloing over chords as (generally) a unified (single) concept.
      <div className="spacer-12"></div>
      <b>Chords</b>: Any chord, in any key, anywhere on the neck.<br />
      Don't try to learn "a million" chords. Instead ...<br />
      learn (generally) one reusable adaptive approach.<br />
      <b>Lead Solo</b> approach: Over any chord, in any key,<br />
      anywhere on the neck.
      <div className="spacer-12"></div>
      <b>PICKING-HAND</b> techniques:<br />
      If you don't have good picking-hand techniques,<br />
      your ideas will stay inside your head.<br />
      Your picking-hand is "equally important" as your neck-hand.<br />
      ____________________________________________
      <div className="spacer-12"></div>
      Lesson schedule can be weekly, every two weeks or negotiated.
      <div className="spacer-12"></div>
    </div>;
  }