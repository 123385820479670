export default function EducationComp() {
    return <div>
      <div className="spacer-12"></div>
      <b>MY FORMAL EDUCATION</b>:
      <div className="spacer-12"></div>
      College name: Berklee College of Music<br />
      Location: Boston, Massachusetts, USA<br />
      Time period: Jan 1990 to Dec 1994<br />
      I graduated high "magna cum laude" (93%).<br />
      Level of education: College Diploma<br />
      Field of study: Jazz Performance<br />
      & a minor in Big Band Arranging
      <div className="spacer-12"></div>
      To this day, I rigorously develop my Berklee knowledge.<br />
      I always have something interesting to practice.<br />
      I consistently notice self-improvement<br />
      which feeds my drive to practice.
      <div className="spacer-12"></div>
      SIDE-NOTES regarding <b>Picking Techniques</b>.<br />
      I use & (optionally) recommend/teach Hybrid-picking.<br />
      a.k.a. Chicken' Pickin', or Tele-style, Banjo-style.<br />
      (Two fingers & a pick)
      <div className="spacer-12"></div>
    </div>;
  }