import {useEffect, useState} from 'react';
import ButtonComp from './buttonContent';
//=======================================
export default function ScrollToTop(props) {
  const [isVisible, setIsVisable] = useState(false);
  useEffect(
    () => {
      window.addEventListener(
        'scroll',
        toggleVisibilityHandler
      );
      return () => {
        window.removeEventListener(
          'scroll',
          toggleVisibilityHandler
        );
      };
    },
    []
  );
  //- - - - - - - - - - - - - - - -
  function toggleVisibilityHandler() {
    if (window.pageYOffset > 200) {
      setIsVisable(true);
    } else { setIsVisable(false); }
  }
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
  //- - - - - - - - - - - - - - - -
  return <ButtonComp
    clickHandlerProps={scrollToTop}
    titleProps={'Scroll to top'}
    cssClassProps={
      isVisible ? 'scroll-button display-button'
      : 'scroll-button'
    }
  />;
}